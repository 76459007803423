import axios from 'axios';
import { BARE_METAL_BACKEND_URL } from '../../../envs/urls';

export const getLiquidityPool = ({ mintAddress }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `${BARE_METAL_BACKEND_URL}/api/v1/mtb/radiyum-pool`,
      params: {
        mintAddress,
      },
    })
      .then(result => {
        const pools = result?.data?.data;
        const meteoraPools = pools?.filter(pool => pool?.dexId === 'meteora');
        resolve(meteoraPools);
      })
      .catch(err => {
        console.log('error is', err);
        reject('Unable to find meteora liquidity pools.');
      });
  });
};
