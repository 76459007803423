import React, { useMemo, useRef, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import message from 'antd/es/message';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { TokenInfo } from '../components/TokenInfo';
import LoadToken from '../components/LoadToken';
import VolumeBotForm from '../components/VolueBotForm';
import { HyperButton } from '../../../components/buttons/HyperButton';
import {
  BASE_PDA,
  BOT_BASE_FEE,
  BOT_GAS_FEE,
  HYPERPAD_CHARGE,
  JITO_TIP_1X,
} from '../../../envs/vars';
import { BotTransaction } from '../utils/BotTransactions';
import { SolUtils } from '../../../solana/SolUtils';
import { useCreateWallet, useInitCompaign } from '../../../utils/networkCalls';
import { NotifyContext } from '../../../context/Notify';
import { VolumeBotInfo } from '../components/VolumeBotInfo';
import { BOT_DEFAULT_COUNT, BOT_DEFAULT_RATE } from '../utils/helpers';
import { RAYDIUM_VOLUME_TX_AMOUNT_TABS } from '../../../constants';
import { Tutorial } from '../components/VolumeTutorial';
import { LiquidityPools } from '../components/LiquidityPools';
import { CopyString } from '../../../components/CopyString';

const CreateVolume = () => {
  const [tokenDetails, setTokenDetails] = useState(null);
  const [solPrice, setSolPrice] = useState(0);
  const [notifyApi] = useContext(NotifyContext);
  const notifyRef = useRef(null);

  const { connection } = useConnection();
  const wallet = useWallet();
  const [formData, setFormData] = useState({
    amt: RAYDIUM_VOLUME_TX_AMOUNT_TABS[0].value,
    count: BOT_DEFAULT_COUNT,
    rate: BOT_DEFAULT_RATE,
    tip: JITO_TIP_1X,
  });
  const [botAccount, setBotAccount] = useState({
    pubKey: null,
    pvtKey: null,
    campaignId: null,
  });

  const navigate = useNavigate();

  const { mutate: createWallet } = useCreateWallet({
    onSuccess: data => {
      const { pubKey, campaignId, pvtKey } = data.data.data[0] || {};
      setBotAccount({ pubKey, campaignId, pvtKey });
    },
    onError: () => {
      message.error('Internal Server Error, Please contact team!!');
    },
  });

  const { mutate: initCompaign } = useInitCompaign({
    onSuccess: () => {
      let campaignId = notifyRef.current;
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Confirmed',
          description: (
            <div>
              <p>
                Please go to Manage Section to check bot transaction details!!
              </p>
              <a href={`/meteora/manage-bot/${campaignId}`}>BOT DETAILS</a>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
      setTimeout(() => {
        navigate(`/meteora/manage-bot/${campaignId}`);
      }, 10 * 1000);
    },
    onError: err => {
      message.error(`Unable to start bot, ${err?.message || ''}`);
      notifyRef.current &&
        notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Request Failed',
          description: (
            <div>
              <p>
                We are unable to process your request, Please contact team !!
              </p>
            </div>
          ),
          duration: 10,
        });
      notifyRef.current = null;
    },
  });

  const [solAmt, platformFee] = useMemo(() => {
    const {
      count = BOT_DEFAULT_COUNT,
      rate = BOT_DEFAULT_RATE,
      amt,
    } = formData;

    const pdaFee = +BASE_PDA;
    const txnAmount = +formData.amt;
    const txnCount = +count || BOT_DEFAULT_COUNT;
    const txnRate = +rate || BOT_DEFAULT_RATE;
    const txnGasFee = +BOT_GAS_FEE;
    const botBaseFee = +BOT_BASE_FEE;
    const platformFee = +HYPERPAD_CHARGE;

    const solAmt = (
      2 * pdaFee +
      txnAmount +
      txnCount * (txnGasFee + 0.015 * txnAmount)
    ).toFixed(5);

    const pfAmt = (
      botBaseFee +
      txnCount * (platformFee * (1 + txnRate / 200))
    ).toFixed(5);

    // Convert the results back to numbers
    return [parseFloat(solAmt), parseFloat(pfAmt)];
  }, [formData]);

  const createTxn = async () => {
    const pubKey = botAccount.pubKey;
    const campaignId = botAccount.campaignId;
    const txn = await BotTransaction.createTxn({
      connection,
      wallet,
      solAmt,
      platformFee,
      targetAddress: pubKey,
      mintAddress: tokenDetails?.mint,
    });

    const signedTxn = await SolUtils.getSignedTransaction(
      connection,
      txn,
      wallet,
      wallet.publicKey,
    );

    const base64Transaction = Buffer.from(signedTxn.serialize()).toString(
      'base64',
    );

    if (base64Transaction) {
      notifyRef.current = campaignId;
      notifyApi &&
        notifyApi.open({
          key: notifyRef.current,
          placement: 'bottomRight',
          message: 'Processing Request',
          description:
            'We are processing your request, Please wait while we confirm your request!!',
          duration: 60,
        });
      initCompaign({
        campaignId,
        createdBy: wallet.publicKey.toString(),
        txnPerMin: formData.rate || BOT_DEFAULT_RATE,
        jitoEnabled: false,
        jitoRatePerTxn: formData.tip * LAMPORTS_PER_SOL,
        maxTxnLimit: formData.count || BOT_DEFAULT_COUNT,
        exchangeType: 'Meteora',
        tokenMintAddress: tokenDetails?.mint,
        symbol: tokenDetails?.fileData?.symbol,
        tradeAccountKey: pubKey,
        signedTxn: base64Transaction,
        perTxnAmt: formData.amt * LAMPORTS_PER_SOL,
        tokenName: tokenDetails?.metadata?.data?.name || '-',
        poolId: tokenDetails?.liquidityPool || '-',
      });
    }
  };

  return (
    <div className='flex gap-x-[2rem] flex-row md:flex-col md:gap-y-[2rem]'>
      <div className='flex gap-y-[2rem] flex-col w-[70%] md:w-[100%]'>
        <div className='flex flex-col w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
          <h1>
            <span style={{ color: ' #9800ee' }}>Meteora:</span>
            <span>&nbsp;Volume bot</span>
          </h1>
          <div
            style={{
              borderBottom: '2px solid gray',
              marginBottom: '1rem',
              marginTop: '0rem',
            }}></div>
          <LoadToken
            setTokenDetails={setTokenDetails}
            setSolPrice={setSolPrice}
          />
          {tokenDetails ? <TokenInfo tokenDetails={tokenDetails} /> : null}
          <LiquidityPools
            tokenDetails={tokenDetails}
            setTokenDetails={setTokenDetails}
            pools={tokenDetails?.pools}
          />
          <VolumeBotForm
            solPrice={solPrice}
            formData={formData}
            mode='VOLUME'
            setFormData={setFormData}
          />
          <div className='flex flex-row md:flex-col md:gap-y-[2rem] mt-8'>
            <HyperButton
              text={'1. Generate Bot Wallet'}
              className='w-fit mx-auto'
              onClick={() => createWallet({ platform: 'Radiyum' })}
              disabled={
                !tokenDetails ||
                !tokenDetails?.liquidityPool ||
                botAccount.campaignId
              }
            />
            {botAccount.campaignId && (
              <div className='flex flex-col ml-2 mr-2'>
                <p className='m-0'>
                  <CopyString
                    data='Copy bot account private key'
                    dataToCopy={botAccount.pvtKey}
                    style={{ color: 'green' }}
                  />
                </p>
              </div>
            )}
            <HyperButton
              className='w-fit mx-auto'
              text={`2.Pay and Start (${(+solAmt + +platformFee).toFixed(4)})`}
              onClick={() => createTxn()}
              disabled={
                !tokenDetails ||
                !tokenDetails?.liquidityPool ||
                !botAccount.campaignId
              }
              loading={false}
            />
          </div>
        </div>

        {/* <div className='p-6 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
          <Tutorial />
        </div> */}
      </div>

      <div className='w-[30%] md:w-[100%] p-6 gap-y-4 border bg-[var(--main-background-color)] border-[var(--main-border-color)] rounded-[5px]'>
        <VolumeBotInfo title='Volume' />
      </div>
    </div>
  );
};

export default CreateVolume;
