import React, { useState } from 'react';
import Radio from 'antd/es/radio';
import Switch from 'antd/es/switch';
import Input from 'antd/es/input';

import {
  PRIORITY_FEE_SPEED,
  PRIORITY_FEE_TABS,
  TX_AMOUNT_TABS,
} from '../../../constants';
import NumberInput from '../../../components/NumberInput';
import Tooltip from 'antd/es/tooltip';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  getEstimatedVolume,
  getFormattedTime,
  VOLUME_AMOUNT_TABS,
  VOLUME_DEFAULT_COUNT,
  VOLUME_DEFAULT_RATE,
  VOLUME_MAX_RATE,
  VOLUME_MIN_COUNT,
  VOLUME_MIN_RATE,
} from '../utils/helpers';

const VolumeForm = ({ formData, setFormData, solPrice }) => {
  const handleFormDataChange = ({ key, value }) => {
    setFormData({ ...formData, [key]: value });
  };
  const [customAmountEnabled, setCustomAmountEnabled] = useState(false);
  const isBumpMode = false;
  const amountTabs = VOLUME_AMOUNT_TABS;
  return (
    <div className='grid grid-cols-2 lg:grid-cols-1 gap-4 mt-[1rem]'>
      <div className='flex flex-col md:col-span-2 items-baseline gap-y-2'>
        <b>
          Total Txn Count:{' '}
          <Tooltip title='Total number of transactions that you want to execute'>
            <InfoCircleOutlined />
          </Tooltip>
        </b>
        <NumberInput
          className='w-full'
          value={formData.count || VOLUME_DEFAULT_COUNT}
          min={VOLUME_MIN_COUNT}
          onChange={value => {
            handleFormDataChange({ key: 'count', value });
          }}
        />
      </div>
      <div className='flex flex-col md:col-span-2 items-baseline gap-y-2'>
        <b>
          Rate (Txn/min):{' '}
          <Tooltip title='Set transactions per minute to 30-60 to rank in the top 5 on the PumpFun homepage.'>
            <InfoCircleOutlined />
          </Tooltip>
        </b>
        <NumberInput
          className='w-full'
          max={VOLUME_MAX_RATE}
          min={VOLUME_MIN_RATE}
          value={formData.rate || VOLUME_DEFAULT_RATE}
          onChange={value => {
            handleFormDataChange({ key: 'rate', value });
          }}
        />
      </div>
      <p>
        <b>Estimated Time:</b> {getFormattedTime(formData.count, formData.rate)}
      </p>
      <p>
        <b>Estimated Volume:</b>{' '}
        {getEstimatedVolume(formData.count, formData.amt, solPrice)}
      </p>
      {/* <div className='flex col-span-2 flex-col items-baseline gap-y-2'>
        <b>
          Txn Amount (SOL):{' '}
          <Tooltip title='Amount of sol that you want to buy and sell in each transaction.'>
            <InfoCircleOutlined />
          </Tooltip>
        </b>
        <Radio.Group
          value={formData.amt}
          name='amt'
          onChange={e => {
            const { value, name: key } = e.target || {};
            handleFormDataChange({ key, value });
          }}
          buttonStyle='solid'
          optionType='button'
          className='flex w-full'
        >
          {VOLUME_AMOUNT_TABS.map(tab => (
            <Radio.Button
              key={tab.value}
              value={tab.value}
              className='flex flex-1 items-center justify-center h-10 text-[#666]'
            >
              {tab.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </div> */}
      <div className='flex col-span-2 flex-row items-center gap-x-2'>
        {!isBumpMode && (
          <Tooltip title='Toggle this to add custom txn amount in bot'>
            <Switch
              onChange={() => {
                customAmountEnabled &&
                  handleFormDataChange({
                    key: 'amt',
                    value: amountTabs[0].value,
                  });
                setCustomAmountEnabled(prev => !prev);
              }}
              checked={customAmountEnabled}></Switch>
          </Tooltip>
        )}
        <p style={{ marginBottom: '0rem' }}>
          <b>
            {`${isBumpMode ? 'Txn Amount (SOL)' : 'Custom Txn Amount (SOL)'}`}{' '}
            &nbsp;
            <Tooltip title='Amount of sol that you want your bot txn to buy and sell tokens'>
              <InfoCircleOutlined />
            </Tooltip>
          </b>
        </p>
      </div>
      {!customAmountEnabled && (
        <div className='flex col-span-2 flex-col items-baseline gap-y-2'>
          <Radio.Group
            value={customAmountEnabled ? null : formData.amt}
            name='amt'
            onChange={e => {
              if (+e.target.value < 0) {
                return;
              }
              const { value, name: key } = e.target || {};
              handleFormDataChange({ key, value });
            }}
            buttonStyle='solid'
            optionType='button'
            className='flex w-full'>
            {amountTabs.map(tab => (
              <Radio.Button
                key={tab.value}
                value={tab.value}
                className='flex flex-1 items-center justify-center h-10 text-[#666]'>
                {tab.label}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
      )}
      {customAmountEnabled && (
        <div className='flex flex-col md:col-span-2 items-baseline gap-y-2'>
          <Input
            size='large'
            value={formData.amt}
            className='w-full'
            name='amt'
            suffix='SOL'
            onChange={e => {
              if (+e.target.value < 0) {
                return;
              }
              const { value, name: key } = e.target || {};
              handleFormDataChange({ key, value });
            }}
          />
        </div>
      )}
      {/* <div className='flex col-span-2 flex-col items-baseline gap-y-2'>
        <b>Jito tip (Per Txn):</b>

        <Radio.Group
          value={formData.tip}
          name='tip'
          onChange={e => {
            const { value, name: key } = e.target || {};
            handleFormDataChange({ key, value });
          }}
          buttonStyle='solid'
          optionType='button'
          className='flex w-full'>
          {PRIORITY_FEE_TABS.map(tab => (
            <Radio.Button
              key={tab.value}
              value={tab.fee}
              className='flex flex-auto items-center justify-center h-10 text-[#666]'>
              <div className='flex items-center justify-between gap-x-4'>
                <span className='md:text-xs text-center'>
                  {tab.label}&nbsp;
                  <span className='text-xs'>{tab.fee}</span>
                </span>
                <div className='flex sm:hidden items-center justify-center h-[18px] min-w-[28px] rounded-[10px] bg-[#ba4ff6] font-extralight text-[11px] text-white'>
                  {PRIORITY_FEE_SPEED[tab.value]}
                </div>
              </div>
            </Radio.Button>
          ))}
        </Radio.Group>
      </div> */}
    </div>
  );
};

export default VolumeForm;
