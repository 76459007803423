import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HelmetLayout from '../../components/HelmetLayout';
import Manage from './container/Manage';
import Details from './container/Details';
import CreateBump from './container/CreateBump';
import CreateVolume from './container/CreateVolume';

const Meteora = () => {
  return (
    <>
      <HelmetLayout
        title='Volume Bot: Generate Volume for your tokens on Meteora'
        description="Volume bot makes multiple trades boosting your token's onchain volume"
        ogImage='https://app.hypersol.xyz/images/raydium_bolume_bot.png'
      />
      <Routes>
        <Route path='bump-bot' element={<CreateBump />} />
        <Route path='volume-bot' element={<CreateVolume />} />
        <Route path='manage-bot' element={<Manage />} />
        <Route path='manage-bot/:campaignId' element={<Details />} />
      </Routes>
    </>
  );
};

export default Meteora;
