import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Layout from 'antd/es/layout';
import { Content } from 'antd/es/layout/layout';
import { Route, Routes } from 'react-router-dom';
import { StonkSider } from './Sider';
import layoutStyle from '../style/layout.module.less';
import { LaunchPad } from '../subapps/LaunchPad';
import { StonkNavBar } from './navbar';
import { Home } from '../subapps/Home';
import { MultiSender } from '../subapps/MultiSender';
import { Locker } from '../subapps/Locker';
import { Burner } from '../subapps/Burner';
import { TokenScanner } from '../subapps/TokenScanner';
import { SnapShot } from '../subapps/Snapshot';
import { Incinerator } from '../subapps/Incinerator';
import WithFooter from '../hocs/WithFooter';
import { ManageWallet } from '../subapps/ManageWallet';
import Bumpi from '../subapps/Bumpi';
import { useMenu } from '../store/useMenu';
import useDevice from '../hooks/useDevice';
import MoonShot from '../subapps/Moonshot';
import RaydiumBot from '../subapps/VolumeBot';
import Meteora from '../subapps/Meteora';
import Orca from '../subapps/Orca';

export const PadLayout = () => {
  const [isNavBarOpen, dispatchMenu] = useMenu(state => [
    state.isNavBarOpen,
    state.dispatch,
  ]);
  const { isMobile } = useDevice();
  const contentRef = useRef(null);

  // to close left menu bar on clicking outside
  useEffect(() => {
    const handleClickOnContent = event => {
      if (contentRef.current.contains(event.target)) {
        dispatchMenu({ type: 'TOGGLE_MENU' });
      }
    };
    if (isMobile && isNavBarOpen && contentRef.current) {
      document.addEventListener('click', handleClickOnContent);
    }
    return () => {
      document.removeEventListener('click', handleClickOnContent);
    };
  }, [isNavBarOpen, isMobile]);

  return (
    <Layout class='flex h-screen'>
      <StonkSider />
      <RightPanel ref={contentRef} />
    </Layout>
  );
};

const RightPanel = WithFooter(props => (
  <>
    <StonkNavBar />
    <div className={layoutStyle.layoutBox}>
      <Content className={layoutStyle.layoutContent}>
        {/* <Routes>{allRoutes}</Routes> */}
        <Routes>
          {/* <Route path="/launchpad/*" element={<LaunchPad />} /> */}
          {/* <Route path="/airdrop/*" element={<AirDrop />} /> */}
          <Route path='/' element={<Home />} />
          <Route path='/solana-multi-sender/*' element={<MultiSender />} />
          <Route path='/manage-wallet/*' element={<ManageWallet />} />
          <Route path='/locker/*' element={<Locker />} />
          <Route path='/solana-token-manager/*' element={<LaunchPad />} />
          <Route path='/launch/*' element={<LaunchPad />} />
          <Route path='/solana-token-burner/*' element={<Burner />} />
          <Route path='/scanner/*' element={<TokenScanner />} />
          <Route path='/snapshot/*' element={<SnapShot />} />
          <Route path='/solana-incinerator/*' element={<Incinerator />} />
          {/* for redirect purpose this duplicate is there */}
          <Route path='/pumpfun-bump-bot/*' element={<Bumpi />} />
          <Route path='/pumpfun/*' element={<Bumpi />} />
          {/* for redirect purpose this duplicate is there */}
          <Route path='/raydium/*' element={<RaydiumBot />} />
          <Route path='/solana-volume-bot/*' element={<RaydiumBot />} />
          {/* for redirect purpose this duplicate is there */}
          <Route path='/moonshot-bot/*' element={<MoonShot />} />
          <Route path='/moonshot/*' element={<MoonShot />} />
          <Route path='/meteora/*' element={<Meteora />} />
          <Route path='/orca/*' element={<Orca />} />
        </Routes>
      </Content>
    </div>
  </>
));
